/* src/PerformanceCard.css */

.performance-card {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  font-family: Arial, sans-serif;
}

.performance-card h2 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.performance-card p {
  font-size: 0.9em;
  margin-bottom: 20px;
}

.performance-metrics {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.metric {
  /* border-top: 1px dashed #ccc; */
  padding-top: 10px;
}

.metric h3 {
  color: black;
  font-size: 1em;
  margin: 0;
}

.metric p {
  font-size: 0.9em;
  margin: 5px 0 0 0;
}


.removeButtonrnp {
  color: white;
  background-color: red;
}

.addvideourlrnp {
  color: white;
  background-color: rgb(24, 112, 229);
}



/* src/components/EstimatedPerformance.css */
/* src/components/EstimatedPerformance.css */
.performance-card1 {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  width: 350px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-bottom: 20px;
  margin-top: 30px;
}

.performance-card1 h3 {
  margin-top: 0;
  font-size: 18px;
  color: #333;
}

.performance-card1 p {
  font-size: 14px;
  color: #666;
}

.performance-card1 ul {
  padding-left: 20px;
}

.performance-card1 li {
  font-size: 14px;
  color: #333;
  list-style: none;
  margin-bottom: 8px;
}

.performance-card1 input {
  display: block;
  margin-top: 5px;
}

.checkmark1 {
  color: green;

  font-weight: bold;
  margin-left: 5px;
}

.mainbox {
  position: relative;
}

.fixedbox {
  position: fixed;
  z-index: 0;
}


.campaign-estimates {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e8f4fe;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  margin: 20px auto;
}

.info-box {
  display: flex;
  align-items: center;
}

.info-icon {
  display: inline-block;
  background-color: #007bff;
  color: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  font-weight: bold;
  margin-right: 10px;
}

p {
  margin: 0;
  font-size: 14px;
  color: #333;
}






.content {
  margin-left: 80px;
  /* To make space for the fixed navbar */
  padding: 70px;
  width: calc(100% - 80px);
}

.overview-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e4e4e4;
}

.new-campaign-button {
  background-color: #4285f4;
  color: white;
  border: none;
  padding: 10px 20px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
}

.new-campaign-button:hover {
  background-color: #3172DE;
}

.date-range {
  font-size: 18px;
}

.date {
  font-weight: bold;
}

.controls {
  display: flex;
  gap: 10px;
}

.control-button {
  background-color: white;
  color: black;
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.metrics-container {
  display: flex;

  justify-content: center;

}

.metricO {
  width: 22%;
  padding: 20px;
  color: white;
  /* border-radius: 5px; */
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
  /* Ensure positioning context for the modal */
}

.metricO.blue {
  background-color: #4285f4;
}

.metricO.blue:hover {
  background-color: #3172DE;
}

.metricO.red {
  background-color: #ea4335;
}

.metricO.red:hover {
  background-color: #D7291B;
}

.metricO.orange {
  background-color: #fbbc05;
}

.metricO.orange:hover {
  background-color: #DCA60A;
}

.metricO.green {
  background-color: #34a853;
}

.metricO.green:hover {
  background-color: #239942;
}

.metric-name {
  font-size: 15px;
}

.metric-value {
  font-size: 24px;
  font-weight: bold;
}

.metric-modal {
  position: absolute;
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 200px;

  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}



.metric-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.metric-list li {
  padding: 10px;
  cursor: pointer;
}

.metric-list li:hover {
  background-color: #f0f0f0;
}

.h2withreport {
  font-size: 25px;
  margin-top: 25px;
  margin-bottom: 25px;

}


.navbar {
  position: fixed;
  left: 0;
  top: 70px;
  bottom: 0;
  width: 80px;
  background-color: #fff;
  border-right: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  z-index: 1000;
}

.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  color: #666;
  cursor: pointer;
}

.nav-item.active {
  color: #1a73e8;
}

.nav-item .icon {
  font-size: 24px;
}

.nav-item .label {
  font-size: 12px;
  margin-top: 5px;
  text-align: center;
}


.daterelease {
  /* width: 80%; */

  display: flex;
  justify-content: center;
  background-color: white;
  color: black;
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.custom-datepicker {
  width: 150px;
  /* Adjust the width as needed */
  height: 30px;
  /* Adjust the height as needed */
  font-size: 0.9em;
  /* Adjust the font size as needed */
  padding: 5px;
  /* Adjust the padding as needed */
  border: none;
  border-radius: 4px;
  text-align: center;
}

.h1psb {
  font-size: 20px;
  color: #333;


}



/* src/ThankYouPage.css */

.thank-you-page {
  text-align: center;
  padding: 50px;
}

.message-box {
  background-color: #fef8e7;
  display: inline-block;
  padding: 20px;
  margin-top: 20px;
  border-radius: 10px;
  font-size: 18px;
  color: #333;
}

/* .whale-image {
  margin: auto;
  text-align: center;
  margin-top: 20px;
} */

.buttonthank {
  display: block;
  margin-top: 20px;

}


.custom-scrollbar {
  overflow-y: auto;
  max-height: calc(100vh - 40px);
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: blue;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: darkblue;
}

.selectaccrodingtoID {
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 10px;
}

.center-all {
  text-align: center;
}



@media (max-width: 768px) {
  .overview-header {
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    border-bottom: none;
  }

  .new-campaign-button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .date-range {
    flex-direction: column;
    /* margin-top: 20px; */
  }

  .content {
    margin-left: 0px;
    /* To make space for the fixed navbar */
    padding: 70px 10px 10px 10px;
    width: calc(100% - 80px);
  }

  .h1psb {
    font-size: 15px;
    color: #333;


  }

  .h2withreport {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bold;
  }

}

@media (max-width: 768px) {
  .table-container {
    width: 100%;
    padding: 10px;
  }

  .table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .table th,
  .table td {
    font-size: 0.9em;
    padding: 8px;
  }

  .pagination {
    justify-content: center;
  }
}


@media (max-width: 590px) {

  .daterelease {
    /* width: 80%; */

    display: flex;
    align-items: center;
    background-color: white;
    color: black;
    border: 1px solid #ccc;
    padding: 0px;
    cursor: pointer;
    border-radius: 5px;
  }

  .h1psb {
    font-size: 15px;
    color: #333;


  }

  .overview-header {
    flex-direction: row;
    align-items: center;
    margin-top: 4px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    border-bottom: none;
    /* border: 1px solid red; */
  }

  .content {
    margin-left: 0px;
    /* To make space for the fixed navbar */
    padding: 70px 10px 10px 10px;
    width: 100%;
  }

  .new-campaign-button {
    font-size: 12px;
    height: auto;
    padding: 6px 12px;
  }

  /* .date-range {
    margin-top: 15px;
  } */

  .daterelease h2 {
    font-size: 14px;
  }

  .metrics-container {
    /* border: 1px solid red; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
  }

  .metricO {
    width: 100%;
    padding: 10px;
    color: white;
    /* border-radius: 5px; */
    text-align: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    position: relative;
    /* border: 1px solid black; */
    /* Ensure positioning context for the modal */
  }
}